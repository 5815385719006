.register-container {
  width: 100%;
  text-align: center;
  padding-top: 30px;
}

.register-title {
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 48px;
  background-color: #ff7f11;
  width: fit-content;
  display: inline;
  padding: 12px 60px;
  border-radius: 60px;
  border: 3px solid white;
  margin: 30px 0 0 0;
}

.register-description {
  color: white;
  font-size: 24px;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto 18px auto;
  padding: 60px 0 0 0;
  text-align: justify;
}

.register-row-submit {
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  margin: 30px auto 60px auto;
  gap: 30px;
  justify-content: center;
}

.cont-sub-row-submit {
  width: 100%;
}

.sub-row-submit {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0px auto 12px auto;
  gap: 30px;
  justify-content: center;
}

.input-info {
  margin: 0 !important;
}

.register-btn-submit {
  border: none;
  background-color: #ff7f11;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 12px 18px;
  border-radius: 30px;
  color: white;
  cursor: pointer;
  box-shadow: 0 0 12px rgba(255, 255, 255, 0.9);
  width: 50%;
}

.register-btn-submit:active {
  opacity: 0.5;
}

.blocked-register-row-submit {
  color: black;
  font-size: 18px;
  width: 90%;
  max-width: 900px;
  margin: 60px auto;
  background-color: white;
  border-radius: 12px;
  padding: 12px 6px;
}

@media (max-width: 900px) {
  .register-container {
    padding-top: 30px;
  }

  .register-title {
    font-size: 18px;
    padding: 12px 24px;
    border-radius: 60px;
    border: 3px solid white;
    margin: 0;
  }

  .register-description {
    font-size: 18px;
  }

  .cont-sub-row-submit {
    margin-bottom: 18px;
  }

  .register-row-submit,
  .sub-row-submit {
    flex-direction: column;
    gap: 12px;
  }

  .register-btn-submit {
    width: 100%;
    font-size: 18px;
    margin-top: 0;
  }
}
